<template>
    <figure class="Card" >
        <img class="Card__img" :src="source" :alt="title"/>
        <figcaption className="Card__caption">
          <h1>{{ title }}</h1>
          <p>{{ description }}</p>
        </figcaption>
    </figure>
</template>

<script>
export default {
    name: 'Card',
    functional: true,
    props: {
        title: {
            type: String,
            required: true
        },
        description: {
            type: String,
            default: ''
        },
        source: {
            type: String,
            required: true
        }
    },
    mounted () {
        this.$emit('is-mounted', this.$el)
    }
}
</script>

<style lang="scss" scoped>
    .Card {
        position: relative;
        display: inline-block;
        margin: 0;
        font-size: 150%;
        cursor: pointer;
        width: 400px;
        height: 300px;

        &__caption {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;

            position: absolute;
            left: 3%;
            width: 94%;
            height: 94%;
            top: 3%;
            opacity: 0;

            color: white;
            background-color: rgba(0, 0, 0, 0.8);
            transition: opacity .5s;
        }

        &__img {
            transition: opacity .5s;
            width: 100%;
            height: auto;
            object-fit: cover;
        }

        &:hover {

            .Card__caption {
                opacity: 1;
            }

            h1 {
                transform: translate(0);
                transition: transform .5s;
            }

            p {
                margin: 0 15% 0 15%;
                transform: translate(0);
                transition: transform .5s;
            }

            .Card__img {
                opacity: .7;
                transition: opacity .5s;
            }
        }

        h1 {
            font-variant: small-caps;
            position: relative;
            transform: translateX(10%);
            transition: transform .5s;
            pointer-events:none;
        }

        p {
            margin: 0 15% 0 15%;
            position: relative;
            transform: translateX(-10%);
            transition: transform .5s;
            pointer-events:none;
        }
    }
</style>