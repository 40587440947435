const { ref, computed } = require("vue");

const watchedElements = ref([])

export default function useVisibility () {
    function watchElement ({key}) {
        const newElement = {
            key,
            isVisible: ref(false)
        }
        watchedElements.value.push(newElement)

        return watchedElements.value.find(elt => elt.key === key)
    }

    function findElement (key) {
        return computed(() => {
            let elt = watchedElements.value.find(e => e.key === key)
            if (elt) return elt

            return {}
        })
    }
 
    return {watchElement, findElement}
}