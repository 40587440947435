import { ref } from "vue"

const menuIsVisible = ref(false)

export default function useNavigationMenu () {
    const setVisibility = value => menuIsVisible.value = value

    function showNavigationMenu () {
        setVisibility(true)
    }

    function hideNavigationMenu () {
        setVisibility(false)
    }

    return {
        showNavigationMenu,
        hideNavigationMenu,
        menuIsVisible
    }
}