<template>
  <Duo class="Contact" id="Contact" v-intersect="{handler: (a, b, isIntersecting) => contactElement.isVisible = isIntersecting}">
    <template #left>
        <h4 class="Contact__title">Contact me !</h4>
        <p class="Contact__description">Please feel free to send a message if you have any questions you believe I could answer, or if you just want to say hello</p>
    </template>
    <template #right>
        <ContactForm class="Contact__form" />
    </template>
  </Duo>
</template>

<script>
import Duo from '@/components/Duo'
import ContactForm from '@/components/ContactForm'
import useVisibility from '@/composables/useVisibility'

export default {
    name: 'Contact',
    components: {
        Duo,
        ContactForm
    },
    setup () {
        const { watchElement } = useVisibility()
        const contactElement = watchElement({key: 'contact'})

        return { contactElement }
    }
}
</script>

<style lang="scss" scoped>
.Contact {

    &__title {
        font-size: 3em;
        margin-bottom: 12px;
    }

    &__description {
        font-size: 2em;
    }

    &__form {
        max-width: 85vw;

        @media (min-width: 460px) {
            width: 450px;
        }
    }
}
</style>