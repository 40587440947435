<template>
  <div class="Footer">
      <div class="Footer__network">
          <a href="https://github.com/Eddinos" target="_blank" rel="noopener noreferrer">
            <img src="../assets/github.svg" alt="github_logo">
            <span>Eddinos</span>
          </a>
          <a href="https://www.linkedin.com/in/eddine-djerboua/" target="_blank" rel="noopener noreferrer">
            <img src="../assets/LinkedIn_Logo.svg" alt="linkedin_logo">
            <span>Eddine Djerboua</span>
          </a>
          <a href="https://www.instagram.com/spicytunasandwich/" target="_blank" rel="noopener noreferrer">
            <img src="../assets/instagram.svg" alt="instagram_logo">
            <span>@spicytunasandwich</span>
          </a>
      </div>
      <div class="Footer__separator"></div>
      <div class="Footer__credits">
          <div class="Footer__credit">Loader: <a href="https://loading.io/">loading.io</a></div>
          <div class="Footer__credit">Navigation icon: <a href="https://www.flaticon.com/authors/catalin-fertu" title="Catalin Fertu">Catalin Fertu</a> from <a href="https://www.flaticon.com/" title="Flaticon"> www.flaticon.com</a></div>
      </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
    .Footer {
        background-color: #333;
        color: #ccc;
        padding: 0.5em 0 0.5em 0;

        &__network {
            display: flex;
            justify-content: space-around;
            flex-direction: column;
            align-items: flex-start;
            padding-left: 48px;

            @media (min-width: 460px) {
                flex-direction: row;
            }

            * {
                vertical-align: middle;
            }

            img {
                width: 32px;
                padding: 8px;
            }
        }

        &__separator {
            margin: 24px auto;
            border-bottom: 1px solid #666;
            width: 35%;
            text-align: center;
        }

        a {
            text-decoration: none;
            color: inherit;
        }

        &__credits {
            padding: 18px;
        }
    }
</style>