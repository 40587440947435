<template>
  <AppBar />
  <transition name="slide-right">
    <NavigationMenu v-if="menuIsVisible" />
  </transition>
  
  <router-view v-slot="{ Component }">
    <keep-alive>
      <component :is="Component" />
    </keep-alive>
  </router-view>
  <Footer />
</template>

<script>
import AppBar from '@/components/AppBar'
import Footer from '@/components/Footer'
import NavigationMenu from '@/components/NavigationMenu'
import useNavigationMenu from '@/composables/useNavigationMenu'

export default {
  name: 'App',
  components: {
    AppBar,
    Footer,
    NavigationMenu
  },
  setup () {
    const { menuIsVisible } = useNavigationMenu();

    return { menuIsVisible }
  }
}
</script>

<style lang="scss">
@font-face {
  font-family: "Florence";
  src: url("./assets/Florsn01.ttf");
  font-display: swap;
}

body {
  margin: 0;
}

#app {
  font-family: Florence;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

.fade-enter-active, .fade-leave-active {
  transition: all .5s ease-in-out;
}

.fade-enter-from, .fade-leave-to {
  opacity: 0;
  transform: translateY(1vh) scale(0) /*rotateY(360deg) rotateZ(360deg)*/;
  will-change: scroll-position;
}

.slide-enter-active, .slide-leave-active {
  transition: all .8s ease-in-out;
}

.slide-enter-from {
  opacity: 0;
  transform: translateX(20vw);
  will-change: scroll-position;
}

.slide-leave-to {
  opacity: 0;
  transform: translateX(-20vw);
  will-change: scroll-position;
}

.slide-left-enter-active, .slide-left-leave-active {
  transition: all .8s ease-in-out;
}

.slide-left-enter-from, .slide-left-leave-to {
  opacity: 0;
  transform: translateX(-20vw);
  will-change: scroll-position;
}

.slide-right-enter-active, .slide-right-leave-active {
  transition: all .5s ease-in-out;
}

.slide-right-enter-from, .slide-right-leave-to {
  opacity: 0;
  transform: translateX(20vw);
  will-change: scroll-position;
}

.slide-full {

  &-enter-active, &-leave-active {
    transition: all .8s cubic-bezier(0.445, 0.050, 0.550, 0.950);
  }

  &-enter-from {
    opacity: 0;
    transform: translateX(100vw);
    will-change: scroll-position;

    &.revert {
      transform: translateX(-100vw);
    }
  }

  &-leave-to {
    opacity: 0;
    transform: translateX(-100vw);
    will-change: scroll-position;

    &.revert {
      transform: translateX(100vw);
    }
  }
}
</style>
