<template>
  <div class="AppBar" :class="{ 'AppBar--shrinked': pageScrolled }" v-if="!menuIsVisible">
      <router-link :to="{ name: 'Home' }" 
                   class="AppBar__logo">
        <img src="../assets/ed.png" alt="logo">
      </router-link>
      <div class="AppBar__navigation">
          <router-link class="AppBar__link" :to="{ name: 'Home' }" :class="{ 'exact': !contact.isVisible }" exact>Home</router-link>
          <router-link class="AppBar__link" :to="{ name: 'Home', hash: '#Contact' }" :class="{ 'exact': contact.isVisible }">Contact</router-link>
          <router-link class="AppBar__link exact" :to="{ name: 'Portfolio' }">Portfolio</router-link>
          <router-link class="AppBar__link exact" :to="{ name: 'Resume' }">Resume</router-link>
      </div>
      <svg @click="showNavigationMenu" 
           viewBox="0 0 24 24" 
           class="AppBar__burger" 
           style="color: rgb(170, 204, 255); fill: currentcolor; user-select: none;"><path d="M4 15h16v-2H4v2zm0 4h16v-2H4v2zm0-8h16V9H4v2zm0-6v2h16V5H4z"></path></svg>
  </div>
</template>

<script>
import { computed, ref } from 'vue'
import useNavigationMenu from '@/composables/useNavigationMenu'
import useVisibility from '@/composables/useVisibility'


export default {
    name: 'AppBar',
    setup () {
        const YOffset = ref(0)
        const pageScrolled = computed(() => YOffset.value > 200)

        document.onscroll = () => YOffset.value = window.pageYOffset

        const { showNavigationMenu, menuIsVisible } = useNavigationMenu()

        const { findElement } = useVisibility()   
        const contact = findElement('contact')
        
        return { pageScrolled, showNavigationMenu, menuIsVisible, contact }
    }
}
</script>

<style lang="scss" scoped>
.AppBar {
    height: 9em;
    position: fixed;
    top: 0;
    width: 100vw;
    z-index: 9;
    display: flex;
    justify-content: space-between;
    transition: all 1s ease-in-out;

    &--shrinked {
        height: 4em;
        background-color: #1c0d35;
        opacity: .9;
    }

    &__logo {
        display: flex;
        align-items: center;
        justify-content: center;
        max-width: 48px;
        max-height: 96px;

        @media (min-width: 460px) {
            max-width: none;
            max-height: none;
        }

        img {
            max-height: 50%;
        }
    }

    &__navigation {
        display: none;
        
        @media (min-width: 460px) {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 6%;
        }
    }

    &__burger {
        height: 54px;
        width: 54px;
        margin: 12px;
        transition: all 1s ease-in-out;

        .AppBar--shrinked & {
            height: 48px;
            width: 48px;
            margin: 8px;
        }

        @media (min-width: 460px) {
            display: none;
        }
    }

    &__link {
        color: #acf;
        text-decoration: none;
        border-bottom: 2px solid;
        padding: 8px;
        margin-right: 24px;
        position: relative;

        &.router-link-active.exact {
            color: #ff9226;
            border-color: #ff9226;
            border: 2px solid;
        }

        &::before {
            width: 0;
            height: 0;
            position: absolute;
            bottom: 0;
            left: 0;
            border: 2px solid transparent;
            border-right: none;
            border-bottom: none;
            transition: height .2s linear, width .2s linear .2s;
            content: '';
        }

        &::after {
            width: 0;
            height: 0;
            position: absolute;
            bottom: 0;
            right: 0;
            border: 2px solid transparent;
            border-left: none;
            border-bottom: none;
            transition: height .2s linear, width .2s linear .2s;
            content: '';
        }

        &:not(.router-link-active):hover::before {
            width: calc(50% - 2px);
            height: calc(100% - 2px);
            border-color: #acf;
        }

        &:not(.router-link-active):hover::after {
            width: calc(50% - 2px);
            height: calc(100% - 2px);
            border-color: #acf;
        }
    }
}
</style>