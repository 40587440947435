<template>
  <Duo class="Projects">
    <template #left>
        <h4 class="Projects__title">My projects</h4>
        <p class="Projects__description">Check out my last realization, but also other school and personal works</p>
        <router-link to="/portfolio" 
                     class="Projects__seeMore cta">
            See more projects
        </router-link>
    </template>
    <template #right>
        <router-link to="/portfolio" 
                     v-if="project">
            <Card class="Projects__Card Projects__CardContainer" 
                  :title="project.title" 
                  :description="project.shortDescription"  
                  :source="project.media"/>
        </router-link>        
    </template>
  </Duo>
</template>

<script>
import Duo from '@/components/Duo'
import Card from '@/components/Card'
import useProjects from '@/composables/useProjects'
import { computed } from 'vue'

export default {
    name: 'Projects',
    components: {
        Duo,
        Card
    },
    setup () {
        const { getIds, getProject } = useProjects()
        const ids = getIds()

        const selected = computed(() => {
            if (ids.value) {
                const selId = ids.value[Math.ceil(Math.random() * ids.value.length)]?.id
                return  {
                    id: selId
                }
            }
            return {id: 0}
        })

        const project = getProject(() => selected.value, () => ({ enabled: !!selected.value?.id }))
        
        return { project }
    }
}
</script>

<style lang="scss" scoped>
    .Projects {
        background-color: #f2f2f2;

        &__title {
            font-size: 3em;
            margin-bottom: 12px;
        }

        &__description {
            font-size: 2em;
        }

        &__seeMore {
            border: 2px solid #000;
            margin: 0 auto;
            padding: 15px 8px;
            cursor: pointer;
            text-decoration: none;
            color: #000;
            transition: background-color .25s linear;
            font-size: 1.5em;
            display: block;
            width: fit-content;

            &:hover {
                filter: invert(100%);
                background-color: #6d6e71;
            }
        }

        &__Card {
            max-width: 85vw;

            &Container {
                margin: 24px 0;
            }
        }
    }
</style>