const postData = endpoint => async payload => {
    let data = null
    try {
        const res = await fetch (endpoint,
            {
                headers: {
                    Accept: 'application/json',
                    'Content-type': 'application/json'
                },
                method: 'POST',
                body: JSON.stringify(payload)
            })
        data = await res.json()
    } catch (e) {
        data = e
    }
    return data
}

const postEmail = ({from, name, message}) => postData('https://eddapi.herokuapp.com/contactus')({from, name, message})

export { postEmail }