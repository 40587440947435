<template>
    <div class="ContactForm">
        <transition name="fade">
            <div class="ContactForm__loader" v-if="isSending">
                <img src="../assets/triangles-loader.svg" alt="loader">
            </div>    
        </transition> 
        <transition name="slide">
            <div class="ContactForm__success" v-if="success">
                <span>{{ msg }}</span><br>
                <div class="ContactForm__action">
                    <button @click="success = null">
                        Back to form
                    </button>
                </div>
            </div>
        </transition>
        <form ref="form" :class="{ 'is-sending': isSending || success }">
            <div class="ContactForm__name">
                <InputForm label="first name" v-model="firstName" required :disabled="isSending || success"/>
                <InputForm label="last name" v-model="lastName" required :disabled="isSending || success"/>
            </div>
            <div class="ContactForm__email" :class="{ 'is-dirty': email !== '' }">
                <InputForm label="email" type="email" v-model="email" required :disabled="isSending || success"/>
            </div>
            <div class="ContactForm__message" :class="{ 'is-dirty': message !== '' }">
                <InputForm input-tag="textarea" label="message" v-model="message" rows="8" required :disabled="isSending || success" minlength="5" />
            </div>
            <div class="ContactForm__action">
                <button @click="sendMessage" :disabled="isSending || success">Send message</button>
            </div>
            <div class="ContactForm__error" v-if="success === false">
                {{ msg }}
            </div>
        </form>
    </div>
</template>

<script>
import { reactive, ref, toRefs } from 'vue'
import { postEmail } from '@/api/contact'
import InputForm from '@/components/InputForm'

export default {
    name: 'ContactForm',
    components: {
        InputForm
    },
    setup () {
        const formData = reactive({
            firstName: '',
            lastName: '',
            email: '',
            message: ''
        })
        const form = ref(null)
        const isSending = ref(false)
        const result = reactive({
            success: null,
            msg: ''
        })

        async function sendMessage (e) {
            if (!form.value.checkValidity()) {
                return
            }
            e.preventDefault()
            isSending.value = true
            const { success, msg } = await postEmail({
                    name: formData.firstName + ' ' + formData.lastName,
                    from: formData.email,
                    message: formData.message
                })
            result.success = success
            result.msg = msg
            console.log(result)
            isSending.value = false
        }

        return {
            ...toRefs(formData),
            ...toRefs(result),
            sendMessage,
            form,
            isSending
        }
    }

}
</script>

<style lang="scss" scoped>
    .ContactForm {
        box-shadow: #ddd 0 0 8px 1px;
        border-radius: 12px;
        padding: 16px;
        position: relative;
        overflow: hidden;

        .is-sending {
            filter: blur(2px);
            opacity: .4;
        }

        &__loader {
            position: absolute;
            z-index: 1;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }

        &__name {
            padding: 2em 0 1em;
            display: flex;
            justify-content: space-between;
        }

        &__email {
            text-align: start;
            padding-bottom: 1em;
        }

        &__message {
            text-align: start;
            height: 40%;
            padding-bottom: 2em;
        }

        &__success {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;
            height: 100%;
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            
            span {
                font-size: 24px;
            }
        }

        &__error {
            text-align: end;
            color: crimson;
            padding-top: 12px;
            font-size: 14px;
        }

        &__action {
            text-align: end;

            button {
                border: none;
                background-color: #1d2266dd;
                color: #ff9126;
                border-radius: 8px;
                opacity: .9;
                padding: 1em;
                font-size: 1em;
                transition: opacity .4s ease;
                cursor: pointer;
                outline-color: #ff9126;

                &:hover {
                    opacity: 1;
                }
            }
        }
    }
</style>