import { createStore } from 'vuex'
import { getProjects } from '@/api/projects'

export default createStore({
  state: {
    projects: []
  },
  getters: {
    projects: state => state.projects
  },
  mutations: {
    SET_PROJECTS (state, projects) {
      Object.assign(state, { projects })
    }
  },
  actions: {
    async getProjects ({ commit, state }, page) {
      const projects = await getProjects(page)
      commit(state, projects)
    }
  },
  modules: {

  }
})
