<template>
    <div class="Hero" 
         ref="heroElt"
         @click="scrollPastHero"
         :style="{ backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.2)), url(${backgroundUrl})`, height: `${height}vh` }">
        <h1 class="Hero__title" v-html="title"></h1>
        <h4 class="Hero__subtitle" v-if="subtitle">{{ subtitle }}</h4>
    </div>
</template>

<script>
import { computed, ref } from 'vue';
export default {
    name: "Hero",
    props: {
        title: {
            type: String,
            required: true
        },
        subtitle: {
            type: String
        },
        pictures: {
            type: Array
        },
        height: {
            type: Number
        }
    },
    setup (props) {
        const heroElt = ref(null)

        function scrollPastHero () {
            document.querySelector('html').style.scrollBehavior = 'smooth'
            window.scrollTo(0, heroElt.value.offsetHeight)
            setTimeout(() => {
                document.querySelector('html').style.scrollBehavior = 'unset'
            }, 300);
        }

        const backgroundUrl = computed(() => {
            const makeUrl = picture => `/img/${picture}`

            if (props.bgImg) {
                return makeUrl(props.bgImg)
            }

            let pictures = [
                'bund.jpg',
                'paris.jpg',
                'stockholm.jpg'
            ]

            if (props.pictures) {
                pictures = props.pictures
            }

            const index = Math.floor(Math.random()*pictures.length)
            let picture = pictures[index]

            return makeUrl(picture)
        })

        return {
            scrollPastHero,
            backgroundUrl,
            heroElt
        }
    }
}
</script>

<style lang="scss" scoped>
    .Hero {
        height: 100vh;
        background-size: cover;
        display: flex;
        align-items: center;
        justify-content: center;
        background-repeat: no-repeat;
        background-position: center;
        flex-direction: column;

        &__title {
            color: white;
            font-size: 4em;

            @media (min-width: 460px) {
                font-size: 8em;
            }
        }

        &__subtitle {
            color: white;
            border: 1px solid white;
            border-radius: 8px;
            padding: 16px 9px;
            cursor: pointer;
        }
    }
</style>