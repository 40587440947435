<template>
  <Hero title="Bonjour !<br/> I'm Eddine Djerboua, welcome !" 
        subtitle="Get started" />
  <Me />
  <Projects />
  <Contact />

</template>

<script>
// @ is an alias to /src
import Hero from '@/components/Hero.vue'
import Me from '@/components/HomeMe.vue'
import Projects from '@/components/HomeProjects.vue'
import Contact from '@/components/HomeContact.vue'

export default {
  name: 'Home',
  components: {
    Hero,
    Me,
    Projects,
    Contact
  }
}
</script>
