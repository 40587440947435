<template>
  <div class="InputForm" :class="{ 'is-dirty': isDirty }">
      <component class="InputForm__input" 
                 :is="inputTag" 
                 ref="inputComponent"
                 :type="type" 
                 :name="name" 
                 @input="x => value = x.target.value" 
                 :value="value" 
                 @focus="isDirty = true" 
                 @blur="isDirty = !!value" 
                 v-bind="$attrs" />
      <label :for="name">{{ label }}</label>
  </div>
</template>

<script>
import { computed, ref } from 'vue'
export default {
    name: 'InputForm',
    props: {
      type: {
        type: String,
        default: 'text'
      },
      name: {
        type: String,
        default: ''
      },
      label: {
        type: String,
        default: ''
      },
      inputTag: {
        type: String,
        default: 'input'
      },
      modelValue: {
        default: null
      }
    },
    setup (props, { emit }) {
      const isDirty = ref(false)
      const inputComponent = ref(null)

      const value = computed({
        get: () => {
          return props.modelValue
        },
        set: (v) => {
          emit('update:modelValue', v)
        }
      })

      return {
        isDirty,
        value,
        inputComponent
      }
    }
}
</script>

<style lang="scss" scoped>
  .InputForm {
    position: relative;
    text-align: left;

    label {
        position: absolute;
        transition: all .3s ease-in-out;
        transform-origin: left;
        bottom: 0;
        top: 2px;
        left: 2px;
        height: fit-content;
        cursor: text;
        color: rgb(51, 84, 85);
        pointer-events: none;
    }

    textarea {
      resize: none;
    }

    &__input {
      width: 100%;
      border: none;
      border-bottom: 1px solid rgb(178, 195, 196);
      outline: none;

      .is-dirty &:invalid {
        border-color: crimson;

        & + label {
          color: crimson;
        }
      }

      .is-dirty &:valid {
        border-color: #00d42e;

        & + label {
          color: #01af27;
        }
      }
    }

    &.is-dirty {

        label {
            transform: translateY(-100%) scale(.7);
        }
    }
  }
</style>