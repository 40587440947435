<template>
  <Duo class="Me">
    <template #left>
      <router-link to="/resume" class="Me__picture">
        <img src="../assets/selfie.jpg" alt="selfie">
      </router-link>
    </template>
    <template #right>
      <p class="Me__bio">
        Basketball legend, web development genius, blabla lorem ipsum lorem ipsum who reads these self-descriptions anyway
      </p>
    </template>
  </Duo>
</template>

<script>
import Duo from '@/components/Duo'

export default {
    name: 'Me',
    components: {
        Duo
    }
}
</script>

<style lang="scss" scoped>
.Me {
  display: flex;
  align-items: center;

  &__picture {
      border-radius: 50%;

      img {
          border-radius: inherit;
          height: inherit;
          max-width: 85vw;

          @media (min-width: 460px) {
            max-width: 25vw;
          }
      }
  }

  &__bio {
    font-size: 2em;
  }
}
</style>