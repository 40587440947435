<template>
    <div class="Duo">
        <div class="Duo__element Duo__element--left">
            <slot name="left"></slot>
        </div>
        <div class="Duo__element Duo__element--right">
            <slot name="right"></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: "Duo"
}
</script>

<style lang="scss" scoped>
    .Duo {
        display: flex;
        justify-content: space-between;
        padding: 8%;
        flex-direction: column;

        @media (min-width: 460px) {
            flex-direction: row;
            padding: 8% 12%;
        }

        &__element {

            @media (min-width: 460px) {
                max-width: 40%;
            }
        }
    }
</style>