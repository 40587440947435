<template>
  <div class="NavigationMenu">
      <CloseButton class="NavigationMenu__CloseBtn" @click="hideNavigationMenu"/>
      <div class="NavigationMenu__navigation" @click="hideNavigationMenu">
          <router-link class="NavigationMenu__link" :class="{ 'exact': !contact.isVisible }" :to="{ name: 'Home' }">Home</router-link>
          <router-link class="NavigationMenu__link" :class="{ 'exact': contact.isVisible }" :to="{ name: 'Home', hash: '#Contact' }" exact>Contact</router-link>
          <router-link class="NavigationMenu__link exact" :to="{ name: 'Portfolio' }">Portfolio</router-link>
          <router-link class="NavigationMenu__link exact" :to="{ name: 'Resume' }">Resume</router-link>
      </div>
  </div>
</template>

<script>
import useNavigationMenu from '@/composables/useNavigationMenu'
import useVisibility from '@/composables/useVisibility'
import CloseButton from './CloseButton'

export default {
    name: 'NavigationMenu',
    components: {
        CloseButton
    },
    setup () {
        const { hideNavigationMenu } = useNavigationMenu()

        const { findElement } = useVisibility()   
        const contact = findElement('contact')

        return { hideNavigationMenu, contact }
    }
}
</script>

<style lang="scss" scoped>
    .NavigationMenu {
        height: 100vh;
        width: 100vw;
        background-color: rgba($color: #000000, $alpha: .9);
        position: fixed;
        z-index: 10;

        &__CloseBtn {
            position: absolute;
            top: 5px;
            right: 0;
        }

        &__navigation {
            display: flex;
            flex-direction: column;
            height: 75%;
            margin: 22.5% 0;
            justify-content: center;
            align-items: center;
        }

        &__link {
            color: #acf;
            text-decoration: none;
            border: 2px solid;
            padding: 8px;
            margin-bottom: 24px;
            position: relative;
            font-size: 2em;
            width: 50%;

            &.router-link-active.exact {
                color: #ff9226;
                border-color: #ff9226;
                border: 2px solid;
            }
        }
    }
</style>