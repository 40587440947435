import { ref } from "vue";
import { projectsQuery, projectsIdsQuery, projectQuery } from '../gql/projects'
import { useQuery, useResult } from '@vue/apollo-composable'

const projects = ref([])
const singleProject = ref({})
const projectsIds = ref([])

export default function useProjects () {
    function getProjects () {
        const { result, loading } = useQuery(projectsQuery)
        const projects = useResult(result, {})
        return { projects, loading }
    }

    function getProject (variables = {id: 0}, options) {
        const { result } = useQuery(projectQuery, variables, options)
        return useResult(result, {})
    }

    function getIds () {
        const { result } = useQuery(projectsIdsQuery)
        return useResult(result)
    }

    return {
        getProjects,
        getProject,
        // projectLoading: loading,
        projects,
        singleProject,
        getIds,
        projectsIds,
    }
}