import gql from "graphql-tag"

export const projectsQuery = gql`
    query projects {
        projects {
            title
            shortDescription
            longDescription
            media
            id
            sourceCode
            url
            technos
        }
    }
`

export const projectQuery = gql`
    query project ($id: Int!) {
        project (id: $id) {
            title
            shortDescription
            media
            id
        }
    }
`

export const projectsIdsQuery = gql`
    query count {
        projects {
            id
        }
    }
`