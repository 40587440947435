import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ApolloClient from "apollo-boost"
import { DefaultApolloClient } from "@vue/apollo-composable"

const apolloClient = new ApolloClient({
    uri: `https://eddapi.herokuapp.com/graphql`,
})

const app = createApp(App).provide(DefaultApolloClient, apolloClient).use(store).use(router)

app.directive('intersect', {
    mounted(el, binding) {
        const value = binding.value
        const { handler, options } = typeof value === 'object' ? value : { handler: value, options: {} }

        const observer = new IntersectionObserver((entries, observer) => {
            const isIntersecting = Boolean(entries.find(e => e.isIntersecting))
            handler(entries, observer, isIntersecting)
        }, options)

        observer.observe(el)
    }
})

app.mount('#app')