import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import useMeta from '@/composables/useMeta'

const { setMeta } = useMeta()

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    beforeEnter () {
      setMeta({
        title: 'Eddine shows off',
        description: 'Learn more about the professional side of Eddine Djerboua through this presentational website'
      })
    }
  },
  {
    path: '/about',
    name: 'About',
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
    props: true
  },
  {
    path: '/portfolio',
    name: 'Portfolio',
    component: () => import(/* webpackChunkName: "portfolio" */ '../views/Portfolio.vue'),
    props: true,
    children: [
      {
        path: 'project/:id',
        name: 'project',
        meta: {
          modal: true
        },
        component: () => import(/* webpackChunkName: "project" */ '../components/Project.vue'),
        props: true
      }
    ],
    beforeEnter () {
      setMeta({
        title: 'My projects',
        description: 'Eddine\'s side-projects gallery'
      })
    }
  },
  {
    path: '/resume',
    name: 'Resume',
    component: () => import(/* webpackChunkName: "resume" */ '../views/Resume.vue'),
    beforeEnter () {
      setMeta({
        title: 'My resume',
        description: 'Eddine\'s skills and professional experiences'
      })
    }
  }

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior (to, from) {
    if (to.meta.modal || from.meta.modal) {
      return {}
    }
    if (to.hash) {
      return {
        el: to.hash,
        behavior: to.name === from.name ? 'smooth' : 'auto'
      }
    }
    return { top: 0, behavior: to.name === from.name ? 'smooth' : 'auto' }
  }
})

export default router
